export const FEATURES_SLIVER_PLAN = [
  "Access to all planning and post-death modules",
  "Legal estate planning forms valid in every state and customized to you",
  "Dynamic guidance through the modules personalizes your next steps",
  "Bank-level security for your digital lockbox with all of your information, documents and wishes in one place",
  "Automated deputization process allowing you to decide what and when information is shared with trusted contact(s)",
  "Trusted referrals to the right services at the right time, often at a discount",
];

export const FEATURES_GOLD_PLAN = [
  "Automated form-filling after a loss to save your family time (e.g., freeze credit, remove from mailing lists, apply for benefits, transfer titles, seek abandoned funds)",
  "30 minute personalized onboarding with your dedicated concierge",
  "Concierge support via chat or email",
  "All features listed in Starter Plan",
];

export const FEATURE_PLATINUM_PLAN = [
  "All features listed in Starter and Advanced Plan",
  "1:1 personalized service with your dedicated concierge",
  "Assistance with gathering assets",
  "Coordination of appraisals",
  "Support for insurance and pensions claims",
  "Help tackling subscriptions and account closures",
  "Assistance managing detailed records of all transactions",
];

export const ESTATE_PLANING_LIST = [
  {
    id: 'plan-free',
    title: "Life Vault Tool",
    features: [
      "Secure digital planning vault that guides you through important documents to gather (financials, real estate, dependents, utilities, pets, etc.)",
      "Share documents with the right person at the right time",
      "Advance directive and healthcare power of attorney"
    ],
    isMulti: false,
    price: 0,
    priceLabel: "Free",
    priceDesc: null,
    isPopular: false,
    btnTitle: "Select"
  },
  {
    id: 1,
    name: "pl_expert_guided",
    title: "Expert-Guided",
    features: [
      "Expert-Guided Completion of our Life Vault Tool",
      "Call, chat, and email with concierge to ask questions about creating a comprehensive plan",
      "Our expert team has settled hundreds of estates and consists of attorneys, certified trust and fiduciary advisors, and medical social workers."
    ],
    isMulti: false,
    price: 39.99,
    priceLabel: "$39.99/month",
    priceDesc: "3 Months Minimum",
    isPopular: false,
    btnTitle: "Select",
  },
  {
    id: 2,
    name: "pl_trust",
    title: "Trust",
    features: [
      "Build your trust with an estate planning expert over the phone.",
      "Provide for your children and grandchildren's education, pets, charities, loved ones with special needs.",
      "Protect assets and do tax planning.",
      "A trust will keep your estate plan private, avoid probate, and allow you to manage your assets and assist your heirs for many years after your death."
    ],
    isMulti: false,
    price: 399,
    priceLabel: "$399",
    isPopular: false,
    btnTitle: "Select",
  },
  {
    title: "Will",
    subPlans: [
      {
        id: 3,
        name: "pl_will_self_guided",
        title: "Self-Guided",
        priceLabel: "$149",
        price: 149,
        priceDesc: "Self-Guided",
        features: [
          "Answer a few questions to create a will valid in all states. Decide on asset distribution, child guardianship, power of attorney, specific gifts, and more."
        ],
        btnTitle: "Select",
      },
      {
        id: 4,
        name: "pl_will_expert_guided",
        title: "Expert-Guided",
        priceLabel: "$299",
        price: 299,
        priceDesc: "Expert-Guided",
        features: [
          "Build your will with an estate planning expert over the phone and get help to flesh out your ideas and make your wishes clear."
        ],
        btnTitle: "Select",
      }
    ]
  }
];

export const ESTATE_SETTLEMENT_LIST = [
  {
    id: 'post-death-free',
    title: "Estate Settlement Checklist",
    features: [
      "Post-death checklist: everything you need to do from within the first 48 hours to the next year",
      "Grant permissions to add collaborators to the dashboard",
      "Set reminders for important deadlines for upcoming tasks"
    ],
    isMulti: false,
    price: 0,
    priceLabel: "Free",
    isPopular: false,
    btnTitle: "Select"
  },
  {
    id: 5,
    name: "se_expert_guided",
    title: "Expert-Guided",
    features: [
      "Expert-Guided Completion of our Estate Settlement Checklist Tool",
      "Call, chat, and email with a concierge to ask questions while settling an estate. Our experts have settled hundreds of estates, so you don't have to figure everything out on your own."
    ],
    isMulti: false,
    price: 39.99,
    priceLabel: "$39.99/month",
    priceDesc: "3 Months Minimum",
    isPopular: true,
    btnTitle: "Select",
  },
  {
    id: 6,
    name: "se_concierge_assistance",
    title: "Concierge Assistance",
    features: [
      "Get expert help with settling affairs. As an assistant to the executor, our team can support with time consuming tasks like calling utility companies on your behalf, navigating insurance benefits, handling paperwork, and more.",
      "Our expert concierge team includes attorneys and certified trust and fiduciary advisors who have settled hundreds of estates. They will be there for you every step of the way."
    ],
    isMulti: false,
    price: 3000,
    priceLabel: "$3000",
    priceDesc: "One time payment, per estate",
    isPopular: false,
    btnTitle: "Select",
  }
];

export const PLAN_NAME_MAP = {
  se_expert_guided: "Estate Settlement Expert-Guided",
  se_concierge_assistance: "Estate Settlement Concierge Asistance",
  pl_expert_guided: "Estate Planning Expert-Guided",
  pl_trust: "Estate Planning Trust",
  pl_will_self_guided: "Estate Planning Will Self-Guided",
  pl_will_expert_guided: "Estate Planning Will Expert-Guided",
}