import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionInfoStart } from '../../Redux-Saga/Redux/account';
import { createFinGoalUser, fetchFingoalToken, fetchLinkedBanks } from '../../Services/User';
import config from '../../Config/config';

const OpenBanking = () => {
  const { sessionInfo, token, operateEmail } = useSelector((state) => state.account);
  const dispatch = useDispatch();
  const { currentUser, assignUserList } = sessionInfo;
  const [createRequested, setCreateRequested] = useState(false);
  const [showLinkScript, setShowLinkScript] = useState(false);
  const [loadingLinkedBanks, setLoadingLinkedBanks] = useState(false);
  const [loadingBasicToken, setLoadingBasicToken] = useState(false);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  
  const selectedUser = useMemo(() => {
    if (operateEmail) {
      return assignUserList?.find((itm) => itm.email === operateEmail) || currentUser;
    }
    return currentUser;
  }, [operateEmail, assignUserList, currentUser])
  
  const linkedBanks = useMemo(() => {
    return linkedAccounts.filter((item) => item.CONTAINER === 'bank') || [];
  }, [linkedAccounts]);
  
  const linkedCreditCards = useMemo(() => {
    return linkedAccounts.filter((item) => item.CONTAINER === 'creditCard') || [];
  }, [linkedAccounts]);
  
  const linkedLoans = useMemo(() => {
    return linkedAccounts.filter((item) => item.CONTAINER === 'loan') || [];
  }, [linkedAccounts]);
  
  const linkedInvestments = useMemo(() => {
    return linkedAccounts.filter((item) => item.CONTAINER === 'investment') || [];
  }, [linkedAccounts]);
  
  const createBankUser = async (accessToken) => {
    try {
      await createFinGoalUser(accessToken);
      dispatch(getSessionInfoStart(accessToken));
    } catch (e) {
      console.log('Fast Link Token Generation Failed: ', e);
    }
  }
  
  const getLinkedBanks = async (accessToken = token, fingoalLoginName = selectedUser.fingoal_loginname) => {
    try {
      setLoadingLinkedBanks(true);
      const response = await fetchLinkedBanks(accessToken, fingoalLoginName);
      setLinkedAccounts(response?.data?.accounts || []);
    } catch (e) {
      console.log('Failed to get linked bank accounts: ', e);
    } finally {
      setLoadingLinkedBanks(false);
    }
  }
  
  useEffect(() => {
    // Create new user if it does not exist in fastlink
    if (selectedUser && !selectedUser.fingoal_id && token && !createRequested) {
      createBankUser(token);
      setCreateRequested(true);
    } else if (selectedUser && selectedUser.fingoal_loginname) {
      getLinkedBanks(token, selectedUser.fingoal_loginname);
    }
    // eslint-disable-next-line
  }, [selectedUser, token, createRequested]);
  
  
  const onLinkAccount = async () => {
    try {
      setLoadingBasicToken(true);
      const fingoalResponse = await fetchFingoalToken(token);
      setLoadingBasicToken(false);

      if (fingoalResponse?.data?.token) {
        setShowLinkScript(true);

        window.fastlink.open({
          fastLinkURL: config.fastLinkUrl,
          accessToken: fingoalResponse?.data?.token,
          params: {
            configName: 'aggregation'
          },
          onSuccess: (data) => {
            console.log('Success data: ', data);
          },
          onError: function (data) {
            console.log('Error data', data);
          },
          onClose: function (data) {
            console.log('Close data', data);
            setShowLinkScript(false);
            getLinkedBanks(token, selectedUser.fingoal_loginname);
            setCurrentStep(0);
          },
          onEvent: function (data) {
            console.log('Event data', data);
            setCurrentStep(prev => prev + 1);
          }
        }, 'container-fastlink');
      }
    } catch (e) {
      setLoadingBasicToken(false);
    }
  }
  
  return (
    <Box p={3}>
      {currentStep > 0 && (
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="h3" align="center" sx={{ mb: 1 }}>Find Account</Typography>
          <Typography align="center">
            Search for a bank, credit union, investment, credit card or payment provider to connect to Peacefully.
          </Typography>
        </Box>
      )}
      
      {currentStep === 2 && false && (
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="h3" align="center" sx={{ mb: 1 }}>Automatically import your BANK NAME information</Typography>
          <Typography align="center">
            Connect your accounts by logging in to your financial institution.
          </Typography>
          <Typography align="center">
            For security reasons, you will need to renew this connection every year.
          </Typography>
        </Box>
      )}
      
      <div id="container-fastlink"></div>

      {!showLinkScript && (
        <>
          <Box px={4} py={2} mb={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography align="center" variant="h3" sx={{ mb: 2 }}>
              Connect your financial accounts to Peacefully so important account information flows in automatically.
            </Typography>

            <Typography align="center" sx={{ mb: 1 }}>
              Please add each institution where you have a financial account. Don't forget include checking, savings, CD, credit card, investment, loan, and retirement accounts. If an institution is missing, you can add the information manually in the "Additional Financial Accounts" Section.
            </Typography>
          </Box>
        
          <Box display="flex" justifyContent="center" mb={3}>
            <LoadingButton
              loading={loadingBasicToken}
              variant="contained"
              color="primary"
              onClick={onLinkAccount}
            >
              Add a Financial Account
            </LoadingButton>
          </Box>
          
          {loadingLinkedBanks ? (
            <Box width="100%" minHeight={300} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {linkedBanks?.length > 0 && (
                <Box mb={3}>
                  <Box mb={0.5}>
                    <Typography variant="h6">Checking, Savings, and CD Accounts</Typography>
                  </Box>
                  
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Account Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Financial Institution</TableCell>
                        <TableCell>Current Balance</TableCell>
                        <TableCell>Beneficiary</TableCell>
                      </TableHead>
                      
                      <TableBody>
                        {linkedBanks.map((account, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ wordBreak: 'keep-all', minWidth: 150 }}>
                              {account.accountName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {account.accountNumber}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {account.accountType}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {account.providerName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {account.availableBalance?.currency} {account.availableBalance?.amount?.toLocaleString('en-US')}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {account.displayedName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              
              {linkedCreditCards?.length > 0 && (
                <Box mb={3}>
                  <Box mb={0.5}>
                    <Typography variant="h6">Credit Cards</Typography>
                  </Box>
                  
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Account Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Financial Institution</TableCell>
                        <TableCell>Last Payment Day</TableCell>
                        <TableCell>Running Balance</TableCell>
                        <TableCell>Beneficiary</TableCell>
                      </TableHead>
                      
                      <TableBody>
                        {linkedCreditCards.map((card, index) => (
                          <TableRow key={`${card.id}-${index}`}>
                            <TableCell sx={{ wordBreak: 'keep-all', minWidth: 150 }}>
                              {card.accountName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {card.accountNumber}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {card.accountType}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {card.providerName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {card.lastPaymentDate}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {card.runningBalance?.currency} {card.runningBalance?.amount?.toLocaleString('en-US')}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {card.displayedName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              
              {linkedLoans?.length > 0 && (
                <Box mb={3}>
                  <Box mb={0.5}>
                    <Typography variant="h6">Loans</Typography>
                  </Box>
                  
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Account Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Financial Institution</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Principal Balance</TableCell>
                        <TableCell>Beneficiary</TableCell>
                      </TableHead>
                      
                      <TableBody>
                        {linkedLoans.map((loan, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ wordBreak: 'keep-all', minWidth: 150 }}>
                              {loan.accountName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {loan.accountNumber}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {loan.accountType}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {loan.providerName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {loan.balance?.currency} {loan.balance?.amount?.toLocaleString('en-US')}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {loan.principalBalance?.currency} {loan.principalBalance?.amount?.toLocaleString('en-US')}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {loan.displayedName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              
              {linkedInvestments?.length > 0 && (
                <Box>
                  <Box mb={0.5}>
                    <Typography variant="h6">Investments</Typography>
                  </Box>
                  
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableCell>Account Name</TableCell>
                        <TableCell>Account Number</TableCell>
                        <TableCell>Account Type</TableCell>
                        <TableCell>Account Category</TableCell>
                        <TableCell>Financial Institution</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Beneficiary</TableCell>
                      </TableHead>
                      
                      <TableBody>
                        {linkedInvestments.map((investment, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ wordBreak: 'keep-all', minWidth: 150 }}>
                              {investment.accountName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {investment.accountNumber}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {investment.accountType}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {investment.accountCategory}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {investment.providerName}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}>
                              {investment.balance?.currency} {investment.balance?.amount?.toLocaleString('en-US')}
                            </TableCell>
                            <TableCell sx={{ wordBreak: 'keep-all' }}>
                              {investment.displayedName}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
};

export default OpenBanking;
