import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  Grid,
  Stack
} from "@mui/material";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { AddCollaboratorModal, GetHelpModal } from "../../../Components/modals";
import SuccessModal from "../../../Components/modals/Success";
import {
  increaseBoardCounting,
  setShowIframe
} from "../../../Redux-Saga/Redux/ui";
import ReminderSection from "./ReminderSection";
import { getEmbededData, getQDocs } from "../../../Services/User";
import { PLAN_SURVEY_ID, POST_DEATH_SURVEY_ID } from "../../../constants";
import UpgradeDialog from "../../../Components/Dialogs/UpgradeDialog";
import PDFSlider from './PDFSlider';

const REMINDER_SECTION = "REMINDER_SECTION";
const REMINDER_BUTTON = "REMINDER_BUTTON";

const PostDeathBoard = ({ owner }) => {
  const account = useSelector((state) => state.account);
  const ui = useSelector((state) => state.ui)
  const dispatch = useDispatch();
  const { sessionInfo = {} } = account;
  const { currentUser = {} } = sessionInfo;
  const [qDocs, setQDocs] = useState([]);

  const [addOpen, setAddOpen] = useState(false);
  const [showDashboardItems, setShowDashboardItems] = useState(true);
  const [successInfo, setSuccessInfo] = useState(null);
  const [embeddedData, setEmbeddedData] = useState({});
  const [helpPopUp, setHelpPopUp] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);

  const isLockHelp = useMemo(() => {
    const {
      isPromoted,
      survey_id: userSurveyId,
      planning_subscription,
      post_death_subscription,
      products,
    } = currentUser;

    if (isPromoted) return false;

    if (userSurveyId === PLAN_SURVEY_ID) {
      if (planning_subscription?.status === 'active') return false;
      
      const planningProducts = products.filter((it) => it.category === 'planning');
      return planningProducts.length > 0 ? false : true;
    }

    if (userSurveyId === POST_DEATH_SURVEY_ID) {
      if (post_death_subscription?.status === 'active') return false;
      
      const postDeathProducts = products.filter((it) => it.category === 'post_death');
      return postDeathProducts.length > 0 ? false : true;
    }

    return false
  }, [currentUser]);

  useEffect(() => {
    const messageHandler = (data) => {
      switch (data.data) {
        case "dashboard-load":
          setShowDashboardItems(true);
          break;
        case "dashboard-unload":
          setShowDashboardItems(false);
          break;
        default:
      }
    };

    window.addEventListener("message", messageHandler);
    return () => window.removeEventListener("message", messageHandler);
  }, []);

  useEffect(() => {
    if (owner && account) {
      getEmbededData(account.token, {
        qualtrics_user_id: owner?.qualtrics_user_id
      }).then((res) => {
        setEmbeddedData(res?.data?.embeddedData);
      });
    } else if (account) {
      setEmbeddedData(account?.sessionInfo?.embeddedData);
    }
  }, [account, owner]);

  const passedName = useMemo(() => {
    return embeddedData?.fname_pass || "";
  }, [embeddedData]);

  const passedDate = useMemo(() => {
    return embeddedData?.pass_date;
  }, [embeddedData]);

  const due48Hours = useMemo(() => {
    return moment(passedDate).add("days", 2).format("MM/DD/YYYY");
  }, [passedDate]);

  const due1Week = useMemo(() => {
    return moment(passedDate).add("weeks", 1).format("MM/DD/YYYY");
  }, [passedDate]);

  const due1Month = useMemo(() => {
    return moment(passedDate).add("months", 1).format("MM/DD/YYYY");
  }, [passedDate]);

  const due3Months = useMemo(() => {
    return moment(passedDate).add("months", 3).format("MM/DD/YYYY");
  }, [passedDate]);

  const handleOpenChat = () => {
    if (isLockHelp) setShowUpgrade(true);
    else setHelpPopUp(true);
  };
  
  const handleLoadQDocs = async () => {
    try {
      const result = await getQDocs(account.token);
      const files = result?.data?.data?.map((it) => {
        const nameArr = it.url.split('.');
        const type = nameArr[nameArr.length - 1];
        return { ...it, type: type.toUpperCase() };
      }) || [];
      setQDocs(files);
    } catch (error) {
      console.log("failed to load qualtrics docs")
    }
  }
  
  useEffect(() => {
    if (account?.user) {
      handleLoadQDocs();
    }
    // eslint-disable-next-line
  }, [account]);
  
  const docData = useMemo(() => {
    const data = [];
    if (currentUser?.dpoa_sign_url) {
      const nameArr = currentUser.dpoa_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];

      data.push({
        fileName: "DPOA",
        type: type.toUpperCase(),
        downloadLink: currentUser?.dpoa_sign_url,
        key: "dpoa_sign_url",
      });
    }
    if (currentUser?.hcpoa_sign_url) {
      const nameArr = currentUser.hcpoa_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "HCPOA",
        type: type.toUpperCase(),
        downloadLink: currentUser?.hcpoa_sign_url,
        key: "hcpoa_sign_url",
      });
    }
    if (currentUser?.live_willing_sign_url) {
      const nameArr = currentUser.live_willing_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "LIVING WILL",
        type: type.toUpperCase(),
        downloadLink: currentUser?.live_willing_sign_url,
        key: "live_willing_sign_url",
      });
    }
    if (currentUser?.will_sign_url) {
      const nameArr = currentUser.will_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];
      
      data.push({
        fileName: "WILL SIGN URL",
        type: type.toUpperCase(),
        downloadLink: currentUser?.will_sign_url,
        key: "will_sign_url",
      });
    }
    if (currentUser?.will_trust_sign_url) {
      const nameArr = currentUser.will_trust_sign_url.split('.');
      const type = nameArr[nameArr.length - 1];

      data.push({
        fileName: "WILL WITH TRUST",
        type: type.toUpperCase(),
        downloadLink: currentUser?.will_trust_sign_url,
        key: "will_trust_sign_url",
      });
    }

    return data;
  }, [currentUser]);

  const handleClickDashboard = () => {
    dispatch(increaseBoardCounting());
    setShowDashboardItems(true);
    dispatch(setShowIframe(false));
    if(ui.activeMenu?.id) {
      setTimeout(() => {
        document.getElementById('qualtricsId').contentWindow.postMessage(`${ui.activeMenu?.id}`, '*');
      }, [2000])
    }
  };

  if (!showDashboardItems) {
    return (
      <Box pt={10} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{ mb: { xs: 4, sm: 0 } }}
              display="flex"
              height="100%"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={handleClickDashboard}
              >
                <ArrowBackIosIcon sx={{ color: "grey.600" }} />
                <Typography fontWeight={600} color="grey.600">
                  Back to Dashboard
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Stack direction="row-reverse" spacing={2}>
              <Box
                sx={{
                  borderRadius: 30,
                  cursor: "pointer",
                  boxShadow: "0px 0px 3px 1px #ddd"
                }}
                height={60}
                px={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={handleOpenChat}
              >
                <Avatar
                  alt="Account"
                  variant="circular"
                  sx={{ width: 30, height: 30 }}
                  src={require("../../../Assets/Images/account-phone.jpg")}
                />
                <Typography
                  sx={{ fontWeight: 700, ml: 1 }}
                  color="primary.light"
                >
                  Need Help?
                </Typography>
              </Box>
              <ReminderSection type={REMINDER_BUTTON} />
            </Stack>
          </Grid>
        </Grid>
        <GetHelpModal open={helpPopUp} onClose={() => setHelpPopUp(false)} />

        {showUpgrade && <UpgradeDialog handleClose={() => setShowUpgrade(false)} />}
      </Box>
    );
  }

  return (
    <Box pt={5}>
      <Typography variant="h2" sx={{ fontWeight: 700 }}>
        {passedName}’s Estate
      </Typography>

      <Box display="flex" justifyContent="flex-end" mb={1}>
        {!owner && (
          <Button
            startIcon={<PersonAddAlt1Icon />}
            size="large"
            sx={{ fontWeight: "700" }}
            onClick={() => setAddOpen(true)}
          >
            Add Collaborators
          </Button>
        )}
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            sx={{
              borderRadius: 3.25,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow:
                "0px 2px 6px 1px #eee, 0px 1px 1px 0px #eee, 0px 1px 3px 0px #eee"
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pl={1}
                mb={1}
              >
                <Typography variant="h6" fontSize="18px">
                  Important Dates
                </Typography>

                <Box width={40} height={40}></Box>
              </Box>

              <Box pl={4}>
                <ul style={{ listStyle: "disc" }}>
                  <li>
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                      {passedName}’s date of death: {passedDate}
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                      <span style={{ color: "#264274" }}>
                        First 48 Hour tasks
                      </span>
                      <span>{` due: `}</span>
                      <span>{due48Hours}</span>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                      <span style={{ color: "#264274" }}>1 Week tasks</span>
                      <span>{` due: `}</span>
                      <span>{due1Week}</span>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                      <span style={{ color: "#264274" }}>1 Month tasks</span>
                      <span>{` due: `}</span>
                      <span>{due1Month}</span>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6" sx={{ fontWeight: "400" }}>
                      <span style={{ color: "#264274" }}>3 Month tasks</span>
                      <span>{`  due: `}</span>
                      <span>{due3Months}</span>
                    </Typography>
                  </li>
                </ul>
              </Box>
            </CardContent>

            <Box display="flex" justifyContent="flex-end" pb={1} px={1}>
              <IconButton size="small">
                <KeyboardArrowLeftIcon />
              </IconButton>

              <IconButton size="small">
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <ReminderSection type={REMINDER_SECTION} />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Card
            sx={{
              borderRadius: 3.25,
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow:
                "0px 2px 6px 1px #eee, 0px 1px 1px 0px #eee, 0px 1px 3px 0px #eee"
            }}
          >
            <CardContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography variant="h6" sx={{ fontSize: "18px" }}>
                  Need Help?
                </Typography>
                <Avatar
                  alt="Account"
                  src={require("../../../Assets/Images/account-phone.jpg")}
                />
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "400" }}>
                  Your Estate Settlement Expert is ready to provide guidance and assistance for all of your questions.
                </Typography>
              </Box>
            </CardContent>
            <Box m={2}>
              <Button
                variant="contained"
                sx={{ width: "100%" }}
                onClick={handleOpenChat}
              >
                Chat with Expert
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Box mt={3} mb={1}>
        <Card
          sx={{
            borderRadius: 3.25,
            boxShadow:
              "0px 2px 6px 1px #eee, 0px 1px 1px 0px #eee, 0px 1px 3px 0px #eee",
            mb: 8
          }}
        >
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
            >
              <Select
                variant="standard"
                value={"all_document"}
                sx={{ fontWeight: "800" }}
              >
                <MenuItem value="all_document">View All Documents</MenuItem>
              </Select>
            </Box>

            <Box>
              <PDFSlider legalDocs={docData} qDocs={qDocs} />  
            </Box>
          </CardContent>
        </Card>
      </Box>

      <AddCollaboratorModal
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onOk={() => {
          setAddOpen(false);
          setSuccessInfo({
            title: "Collaborator Added",
            desc: "Johnson Downy has been added as a collaborator."
          });
        }}
      />
      <SuccessModal
        title={successInfo?.title}
        description={successInfo?.desc}
        open={!!successInfo}
        onClose={() => setSuccessInfo(null)}
        onOk={() => setSuccessInfo(null)}
      />

      {showUpgrade && <UpgradeDialog handleClose={() => setShowUpgrade(false)} />}
    </Box>
  );
};

export default PostDeathBoard;
