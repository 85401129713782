import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Amplify from "aws-amplify";
import config from "../Config/config";
import FredHutch from "../Pages/FredHutch";
import FredLanding from "../Pages/FredLanding";
import Login from "../Pages/AuthV2/Login";
import ResetPassword from "../Pages/AuthV2/ResetPassword";
import SignUp from "../Pages/AuthV2/Signup";
import SignupProgress from "../Pages/AuthV2/SignupProgress";
import GetStarted from "../Pages/GetStarted";
import SignUpNow from "../Pages/SignUpNow";
import Dashboard from "../Pages/Dashboard";
import Help from "../Pages/Help";
import Collaborators from '../Pages/Collaborators'
import MyAccount from "../Pages/MyAccount";
import Documents from "../Pages/Documents";
import Plan from "../Pages/Plan";
import CopyRight from "../Pages/CopyRight";
import DeputyList from "../Pages/DeputyList";
import OpenBanking from '../Pages/OpenBanking';
import { useSelector } from "react-redux";
import { getDomainName } from "../Services/Utils/helper";
import mixpanel from "../mixpanel";
import usePageTracking from "../Hooks/usePageTracking";
import Private from "./Private";

const Router = () => {
  const { user, referer, sessionInfo } = useSelector((state) => state.account);
  const { currentUser } = sessionInfo;

  useEffect(() => {
    if (user && currentUser) {
      const domainName = getDomainName(referer);
      mixpanel.identify(user.email);

      mixpanel.people.set({
        Email: user.email,
        Name: `${user.first_name} ${user.last_name}`,
        Phone: user.mobile,
        source: user?.source || "",
        Platform: "Mixpanel",
      });

      mixpanel.track("Visit", {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        phone: user.mobile,
        originReferDomain: domainName,
        originReferer: referer,
        source: user?.source || "",
      });
    }
  }, [user, referer, currentUser]);

  useEffect(() => {
    Amplify.configure({ Auth: config.aws });
  }, []);

  usePageTracking(user || {});

  return (
    <Routes>
      <Route path="/getstarted" element={<GetStarted />} />
      <Route path="/signupnow" element={<SignUpNow />} />
      <Route path="/fredhutch" element={<FredHutch />} />
      <Route path="/landing" element={<FredLanding />} />
      <Route path="/copy-right" element={<CopyRight />} />
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/signup" element={<SignUp />} />
      <Route path="/auth/reset" element={<ResetPassword />} />

      <Route element={<Private />}>
        <Route path="/auth/progress" element={<SignupProgress />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-deputies" element={<DeputyList />} />
        <Route path="/help" element={<Help />} />
        <Route path="/collaborators" element={<Collaborators />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/plan" element={<Plan />} />
        <Route path="/open-banking" element={<OpenBanking />} />
      </Route>

      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Routes>
  );
};

export default Router;
