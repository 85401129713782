import { Box, Dialog, IconButton, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import Slider from "react-slick";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FileUploader from '../../../../Components/FileUploader';

import FormContainer from '../../../../Components/Form/FormContainer';
import Input from '../../../../Components/Form/Input';
import { LoadingButton } from '@mui/lab';
import { userUploadPostDeathDoc } from '../../../../Services/User';
import { getSessionInfoStart } from '../../../../Redux-Saga/Redux/account';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PDFSlider = ({ legalDocs = [], qDocs = [] }) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  const sliderRef = useRef(null);
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [ignoreClick, setIgnoreClick] = useState(false);
  
  const { sessionInfo } = account || {};
  const { currentUser } = sessionInfo || {};
  
  const directDocs = useMemo(() => {
    const docs = currentUser?.direct_docs || [];
    return docs.map((doc) => {
      const nameArr = doc.split('/');
      const name = nameArr[nameArr.length - 1];
      const nameTypeArr = name.split('.');
      return { url: doc, name: `${decodeURIComponent(nameTypeArr[0])}.${nameTypeArr[1]}` };
    }) || [];
  }, [currentUser]);
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("File name should not be empty"),
  });
  
  const handleAfterChange = (slide) => {
    setCurrentSlide(slide);
    setIgnoreClick(false);
  };
  
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    afterChange: handleAfterChange,
    beforeChange: () => setIgnoreClick(true)
  };
  
  const onClickNext = () => {
    sliderRef.current.slickNext();
  };
  
  const onClickPrev = () => {
    sliderRef.current.slickPrev();
  };
  
  const handleFile = (evt) => {
    setFile(evt.target.files[0]);
  };
  
  const handleSubmit = async ({ name }) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", name);
      
      const result = await userUploadPostDeathDoc(account.token, formData);

      if (result?.data?.data?.success) {
        toast.success("Document is uploaded successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
        dispatch(getSessionInfoStart(account.token));
        
        setFile();
        setLoading(false);
      }
    } catch (error) {}
  };
  
  const initialName = useMemo(() => {
    if (!file) return '';
    const nameArr = file.name.split('.');
    nameArr.pop();
    return nameArr.join('.');
  }, [file])
  
  return (
    <Box>
      <Slider ref={sliderRef} {...settings}>
        <FileUploader
          width={180}
          height={150}
          borderRadius="20px"
          accept="application/pdf"
          fileList={file ? [] : [file]}
          onFile={handleFile}
        />
        
        {directDocs.map((item, index) => (
          <Box
            key={`${index}-${item.url}`}
            href={item.url}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => !ignoreClick && window.open(item.url, '_blank')}
          >
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <img src="https://www.science.co.il/internet/browsers/PDF-doc-256.png" alt="PDF" height={110} />  
            </Box>
            
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} align="center">
              {item.name}
            </Typography>
          </Box>  
        ))}

        {legalDocs.map((item, index) => (
          <Box
            key={`${index}-${item.downloadLink}`}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => !ignoreClick && window.open(item.downloadLink, '_blank')}
          >
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <img src="https://www.science.co.il/internet/browsers/PDF-doc-256.png" alt="PDF" height={110} />  
            </Box>
            
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} align="center">
              {item.fileName}
            </Typography>
          </Box>  
        ))}
        
        {qDocs.map((docItem, index) => (
          <Box
            key={`${index}-${docItem.url}`}
            href={docItem.url}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => !ignoreClick && window.open(docItem.url, '_blank')}
          >
            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
              <img src="https://www.science.co.il/internet/browsers/PDF-doc-256.png" alt="PDF" height={110} />  
            </Box>
            
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} align="center">
              {docItem.fileName}
            </Typography>
          </Box>
        ))}
      </Slider>
      
      <Box display={directDocs.length + legalDocs.length + qDocs.length < 2 ? 'none' : 'flex'} justifyContent="flex-end" py={1} px={1}>
        <IconButton disabled={currentSlide === 0} onClick={onClickPrev} size="small">
          <KeyboardArrowLeftIcon />
        </IconButton>

        <IconButton disabled={(currentSlide + 3) >= legalDocs.length + qDocs.length + directDocs.length} onClick={onClickNext} size="small">
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
      
      {!!file && (
        <Dialog
          maxWidth="xs"
          open={true}
          PaperProps={{ sx: { borderRadius: 8, overflow: "hidden" } }}
          onClose={() => {}}
        >
          <Box px={4} pt={5} pb={4} width={380}>
            <Box mb={4}>
              <Typography variant="h5" align="center">Please add the file name</Typography>
            </Box>
            
            <FormContainer
              validation={validationSchema}
              defaultValues={{ name: initialName }}
              onSuccess={handleSubmit}
            >
              <Box mb={2}>
                <Input
                  fullWidth
                  normalInput={true}
                  name="name"
                  label="File Name"
                  variant="outlined"
                />
              </Box>
              
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="submit"
                  sx={{ px: 4, py: 1 }}
                >
                  Save
                </LoadingButton>
              </Box>
            </FormContainer>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default PDFSlider;
